
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@import './app/styles/variables.scss';
@import './app/styles/material-style-overwritten.scss';
// Plus imports for other components in your app.

@import 'node_modules/@swimlane/ngx-datatable/index.css';
@import 'node_modules/@swimlane/ngx-datatable/themes/material.css';
@import 'node_modules/@swimlane/ngx-datatable/assets/icons.css';


// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

$v-palette: (
    50 : #e0f3e8,
    100 : #b3e1c6,
    200 : #80cda1,
    300 : #4db97b,
    400 : #26aa5e,
    500 : $v-green,
    600 : #00933c,
    700 : #008933,
    800 : #007f2b,
    900 : #006d1d,
    A100 : $v-green,
    A200 : $v-green,
    A400 : $v-green,
    A700 : $v-green,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$visagio-performance-evaluation-primary: mat.m2-define-palette($v-palette);
$visagio-performance-evaluation-accent: mat.m2-define-palette($v-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$visagio-performance-evaluation-warn: mat.m2-define-palette(mat.$m2-red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$visagio-performance-evaluation-theme: mat.m2-define-light-theme(
  $visagio-performance-evaluation-primary,
  $visagio-performance-evaluation-accent,
  $visagio-performance-evaluation-warn,
);
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($visagio-performance-evaluation-theme);

/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; }

.break-line {
  white-space: pre-wrap;
}

.tooltip-list {
  white-space: pre;
  white-space: pre-line;
}

.tooltip-break-line-centered {
  white-space: pre-line;
  text-align: center;
}

.p-calendar {
  width: max-content;
}

@-moz-keyframes spin { 100% { -moz-transform: rotate(-360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(-360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(-360deg); transform:rotate(-360deg); }}