@import './variables.scss';

.mat-tab-label-content {
  color: black;
}
.mat-tab-label-active {
  opacity: 1 !important;
  .mat-tab-label-content {
    font-weight: bold;
  }
}

// .mat-expansion-panel-header {
//   height: 36px !important;
// }

.mat-expansion-panel {
  border-radius: 6px !important;
  margin-bottom: 10px !important;
}

.mat-form-field-underline {
  display: none !important;
}

.mat-form-field-wrapper {
  padding: 0 !important;
}

.mat-form-field-infix {
  padding: 0 !important;
  border: none !important;
}

.visible-overflow {
  .mat-expansion-panel {
    // Makes the dropdown inside the expansion panel be able to go out of the expansion panel
    overflow: visible !important;
  }
}

.mat-ink-bar {
  width: 210px;
}

.mat-tab-label {
  width: 210px;
}

.mat-menu-item {
  outline: none !important;
}

.mat-menu-item-submenu-trigger::after {
  top: 42% !important;
  left: 15px !important;
  transform: rotate(180deg) !important;
}

.mat-menu-item-submenu-trigger {
  padding-right: 16px !important;
  padding-left: 32px !important;
}

.p-dropdown {
  width: 100%;
}

.p-multiselect {
  width: 100%;
}

.p-sidebar-lg {
  overflow: scroll;
}

.cdk-overlay-container {
  z-index: 10000 !important;
}

.datatable-header-cell-label {
  font-size: 13px;
}
